<template>
  <b-modal
    id="fc-add-row-modal"
    ref="fcAddRowModal"
    centered
    no-close-on-backdrop
    title="Add Row"
    hide-footer
    hide-header-close
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        id="fc-add-row-modal-form"
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <!-- BPU -->
        <validation-provider
          #default="validationContext"
          name="BPU"
          rules="required"
        >
          <b-form-group
            label="BPU"
            label-for="bpu"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.bpu"
              :options="bpuOptions"
              :reduce="val => val.value"
              input-id="bpu"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Brand Category -->
        <validation-provider
          #default="validationContext"
          name="Brand Category"
          rules="required"
        >
          <b-form-group
            label="Brand Category"
            label-for="brand_category"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.brand_category"
              :options="brandCategoryOptions"
              :reduce="val => val.value"
              input-id="brand_category"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

          <!-- APPLICATION FORM -->
        <validation-provider
          #default="validationContext"
          name="Application Form"
          rules="required"
        >
          <b-form-group
            label="Application Form"
            label-for="application_form"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.application_form"
              :options="applicationFormOptions"
              :reduce="val => val.value"
              input-id="application_form"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Full Name -->
        <validation-provider
          #default="validationContext"
          name="Description"
          rules="required"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="newRowData.description"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2 justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const bpuOptions = ref([])
    const brandCategoryOptions = ref([])
    const applicationFormOptions = ref([])

    const vm = getCurrentInstance().proxy

    const blankFiltersData = {
      bpu: '',
      brand_category: '',
      application_form: '',
      description: ''
    }

    const newRowData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetRowData = () => {
      newRowData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }

    const onSubmit = () => {
      emit('add-new-row', newRowData.value)
      resetForm()
      vm.$bvModal.hide('fc-add-row-modal')
    }

    const onCancel = () => {
      // resetRowData()
      resetForm()
      vm.$bvModal.hide('fc-add-row-modal')
    }

    // subscription
    vm.$apollo.addSmartSubscription('getFiltersDataBpu', {
      query: gql`
        subscription getFiltersDataBpu {
          bpuOptions: db_main_curr_month(distinct_on: bpu) {
            label: bpu
            value: bpu
          }
        }
      `,
      result ({data}) {
        bpuOptions.value = data.bpuOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataBrandCategory', {
      query: gql`
        subscription getFiltersDataBrandCategory {
          brandCategoryOptions: db_main_curr_month(distinct_on: brand_category) {
            label: brand_category
            value: brand_category
          }
        }
      `,
      result ({data}) {
        brandCategoryOptions.value = data.brandCategoryOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataApplicationForm', {
      query: gql`
        subscription getFiltersDataApplicationForm {
          applicationFormOptions: db_main_curr_month(distinct_on: application_form) {
            label: application_form
            value: application_form
          }
        }
      `,
      result ({data}) {
        applicationFormOptions.value = data.applicationFormOptions
      },
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRowData)

    return {
      newRowData,
      onSubmit,
      onCancel,

      bpuOptions,
      brandCategoryOptions,
      applicationFormOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
