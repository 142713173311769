<template>
  <b-sidebar
    id="filters-sidebar"
    :visible="isFiltersSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-filters-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Filters
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- BPU -->
          <validation-provider
            #default="validationContext"
            name="BPU"
          >
            <b-form-group
              label="BPU"
              label-for="bpu"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.bpu"
                :options="bpuOptions"
                :reduce="val => [val.value]"
                input-id="bpu"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand Category -->
          <validation-provider
            #default="validationContext"
            name="Brand Category"
          >
            <b-form-group
              label="Brand Category"
              label-for="brand_category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.brand_category"
                :options="brandCategoryOptions"
                :reduce="val => [val.value]"
                input-id="brand_category"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- APPLICATION FORM -->
          <validation-provider
            #default="validationContext"
            name="Application Form"
          >
            <b-form-group
              label="Application Form"
              label-for="application_form"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.application_form"
                :options="applicationFormOptions"
                :reduce="val => [val.value]"
                input-id="application_form"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- APPLICATION FORM -->
          <validation-provider
            #default="validationContext"
            name="Canal"
          >
            <b-form-group
              label="Canal"
              label-for="canal"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.canal"
                :options="canalOptions"
                :reduce="val => [val.value]"
                input-id="canal"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- MONEDA-->
          <!-- <validation-provider
            #default="validationContext"
            name="Moneda"
            rules="required"
          >
            <b-form-group
              label="Moneda"
              label-for="moenda"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.moenda"
                :options="monedaOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="moenda"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Aplicar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFiltersSidebarActive',
    event: 'update:is-filters-sidebar-active',
  },
  props: {
    isFiltersSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const bpuOptions = ref([])
    const brandCategoryOptions = ref([])
    const applicationFormOptions = ref([])
    const canalOptions = ref([])

    const vm = getCurrentInstance().proxy

    const monedaOptions = [
      { label: 'Soles', value: 'Soles' },
      { label: 'Euros', value: 'Euros' },
    ]

    const blankFiltersData = {
      bpu: [],
      brand_category: [],
      application_form: [],
      canal: [],
      moneda: []
    }

    const newFiltersData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetuserData = () => {
      newFiltersData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }

    const onSubmit = () => {
      emit('filters-data', newFiltersData.value)
    }

    // subscription
    vm.$apollo.addSmartSubscription('getFiltersDataBpu', {
      query: gql`
        subscription getFiltersDataBpu {
          bpuOptions: db_main_curr_month(distinct_on: bpu) {
            label: bpu
            value: bpu
          }
        }
      `,
      result ({data}) {
        bpuOptions.value = data.bpuOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataBrandCategory', {
      query: gql`
        subscription getFiltersDataBrandCategory {
          brandCategoryOptions: db_main_curr_month(distinct_on: brand_category) {
            label: brand_category
            value: brand_category
          }
        }
      `,
      result ({data}) {
        brandCategoryOptions.value = data.brandCategoryOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataApplicationForm', {
      query: gql`
        subscription getFiltersDataApplicationForm {
          applicationFormOptions: db_main_curr_month(distinct_on: application_form) {
            label: application_form
            value: application_form
          }
        }
      `,
      result ({data}) {
        applicationFormOptions.value = data.applicationFormOptions
      },
    })
    
    vm.$apollo.addSmartSubscription('getFiltersDataCamal', {
      query: gql`
        subscription getFiltersDataCamal {
          canalOptions: db_main_curr_month(distinct_on: canal) {
            label: canal
            value: canal
          }
        }
      `,
      result ({data}) {
        canalOptions.value = data.canalOptions
      },
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      newFiltersData,
      onSubmit,

      bpuOptions,
      brandCategoryOptions,
      applicationFormOptions,
      canalOptions,
      monedaOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#filters-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
