<template>
  <b-card
    no-body
    class="p-1"
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>
        <custom-bar-chart
          v-if="isLoaded"
          :height="400"
          :chart-data="chartData"
          :options="options"
        >
        </custom-bar-chart>
      </b-card-body>
      <trade-stock
        :filters-data="filtersData"
      ></trade-stock>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard,
  BCardBody,
  BButton,
} from 'bootstrap-vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import CustomBarChart from './CustomBarChart.vue'
import gql from 'graphql-tag'
import TradeStock from './TradeStock.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,

    CustomLoading,
    CustomBarChart,
    TradeStock,
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      isLoaded: true,
      chartData: null,
      options: {
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            clamp: true,
            font: {
              weight: 'bold',
              size: 16,
              family: "Montserrat, Helvetica, Arial, serif",
            },
            formatter: (value, ctx) => {
              const total = ctx.chart.$totalizer.totals[ctx.dataIndex]
              return total[ctx.dataset.stack]
            },
            display: function(ctx) {
              return ctx.datasetIndex === ctx.chart.$totalizer.utmost[ctx.dataset.stack]
            }
          }
        },
        legend: {
          labels: {
            fontSize: 16,
            fontFamily: "Montserrat, Helvetica, Arial, serif",
            fontWeight: "bold"
          }
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        scales: {
          x: {
            stacked: true,
          },
          xAxes: [{
            ticks: {
              fontSize: 16,
              fontFamily: "Montserrat, Helvetica, Arial, serif",
              fontWeight: "bold"
            }
          }],
          y: {
            stacked: true,
            min: 0,
            max: 20,
          },
          yAxes: [{
            ticks: {
              fontSize: 16,
              fontFamily: "Montserrat, Helvetica, Arial, serif",
              fontWeight: "bold"
            }
          }],
          
        }
      } 
    }
  },
  watch: {
    filtersData: function(newVal, oldVal) {
      this.filtersData = newVal
    }
  },
  methods: {
    getClasificacionData(obj, clasificacion, year) {
      if (obj[clasificacion] == undefined || obj[clasificacion][year]  == undefined) return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      return obj[clasificacion][year]
    },
    formatChartData(data) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const lastYear = currentDate.getFullYear() - 1
      const lastYear2 = currentDate.getFullYear() - 2

      const newObjData = {}

      for(let obj of data) {
        let newObjClasificacion = {}
        let objYear = obj.year
        let objClasificacion = obj.clasificacion
        let objData = [
          obj['mes_01'],
          obj['mes_02'],
          obj['mes_03'],
          obj['mes_04'],
          obj['mes_05'],
          obj['mes_06'],
          obj['mes_07'],
          obj['mes_08'],
          obj['mes_09'],
          obj['mes_10'],
          obj['mes_11'],
          obj['mes_12']
        ]

        newObjClasificacion[objYear] = objData
        newObjData[objClasificacion] = {...newObjData[objClasificacion], ...newObjClasificacion}
      }

      return {
        labels: [
          "Enero", 
          "Febrero", 
          "Marzo",
          "Abril", 
          "Mayo", 
          "Junio",
          "Julio", 
          "Agosto", 
          "Septiembre",
          "Octubre", 
          "Noviembre",
          "Diciembre", 
        ],
        datasets: [
          {
            label: `Sell in ${lastYear2}`,
            backgroundColor: "#475795",
            data: this.getClasificacionData(newObjData, "REALES", lastYear2),
            stack: 'Stack 0',
            datalabels: {
              display: false
            }
          }, 
          {
            label: `Sell in ${lastYear}`,
            backgroundColor: "#6783c0",
            data: this.getClasificacionData(newObjData, "REALES", lastYear),
            stack: 'Stack 1',
            datalabels: {
              display: false
            }
          }, 
          {
            label: `Sell in ${currentYear}`,
            backgroundColor: "#3b62b4",
            data: this.getClasificacionData(newObjData, "REALES", currentYear),
            stack: 'Stack 2'
          }, 
          {
            label: "Baseline",
            backgroundColor: "#0032a0",
            data: this.getClasificacionData(newObjData, "BASELINE", currentYear),
            stack: 'Stack 2',
          }, 
          {
            label: "Shopper",
            backgroundColor: "#45a13f",
            data: this.getClasificacionData(newObjData, "SHOPPER", currentYear),
            stack: 'Stack 2',
          }, 
          {
            label: "Launch",
            backgroundColor: "#a70532",
            data: this.getClasificacionData(newObjData, "LAUNCH", currentYear),
            stack: 'Stack 2',
          },
          {
            label: "Promo",
            backgroundColor: "yellow",
            data: this.getClasificacionData(newObjData, "PROMO", currentYear),
            stack: 'Stack 2',
          },
          {
            type: 'line',
            label: 'Sell Out Actual',
            data: this.getClasificacionData(newObjData, "SELL OUT", currentYear),
          }
        ]
      }
    },
  },
  apollo: {
    $subscribe: {
      BarChartData: {
        query: gql`
          subscription data_grafico_barras($customWhere: json = null) {
            function_get_grafico_barras(args: {customWhere: $customWhere}) {    
              clasificacion
              year
              mes_01
              mes_02
              mes_03
              mes_04
              mes_05
              mes_06
              mes_07
              mes_08
              mes_09
              mes_10
              mes_11
              mes_12
            }
          }

        `,
        variables(){
          return {
            customWhere: this.filtersData
          }
        },
        result ({data}) {
          this.chartData = this.formatChartData(data.function_get_grafico_barras)
          this.isLoading = false
        },
      }
    }
  }
}
</script>

<style lang="scss">
</style>
