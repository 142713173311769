<template>
  <div>
    <b-row>
      <b-button
        @click="isFiltersSidebarActive = true"
        variant="primary"
        class="m-1"
      >
        Filters
      </b-button>
    </b-row>
    <b-row class="match-height">
    <sop-filters
      :is-filters-sidebar-active.sync="isFiltersSidebarActive"
       @filters-data="setFiltersData"
    />
    <b-col md="6">
      <sop-pivot-bpu
      :filters-data="filtersData"
      ></sop-pivot-bpu>
    </b-col>

    <b-col md="6">
      <sop-pivot-bpu-variacion
      :filters-data="filtersData"
      ></sop-pivot-bpu-variacion>
    </b-col>

    <b-col cols="12">
      <sop-tpen
      :filters-data="filtersData"
      ></sop-tpen>
    </b-col>

    <b-col cols="12">
      <sop-chart
      :filters-data="filtersData"
      ></sop-chart>
    </b-col>

    <b-col cols="12">
      <sop-fc
      :filters-data="filtersData"
      ></sop-fc>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import { 
  BRow, BCol,
  BButton,
 } from 'bootstrap-vue'
import SopPivotBpu from './SopPivotBpu.vue'
import SopPivotBpuVariacion from './SopPivotBpuVariacion.vue'
import SopTpen from './SopTpen.vue'
import SopChart from './SopChart/SopChart.vue'
import SopFilters from  './SopFilters.vue'
import SopFc from './SopFc/SopFc.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,

    SopPivotBpu,
    SopPivotBpuVariacion,
    SopChart,
    SopFilters,
    SopFc,
    SopTpen,
  },
  data() {
    return {
      isFiltersSidebarActive: false,
      filtersData: null,
    }
  },
  methods: {
    setFiltersData(data) {
      this.filtersData = Object.assign({}, data)
    }
  },
}
</script>

<style lang="scss">
</style>
